import { IYouTubePlaylistService } from "../Interfaces/IYouTubePlaylistService";
import { PlaylistResponse } from "../Models/PlaylistResponse"
import { IYouTubePlaylistConfig } from "../Interfaces/Configuration/IYouTubePlaylistConfig";
import YouTubePlayer from 'youtube-player';
import 'slick-carousel';
import * as $ from 'jquery'; // required for Modaal.
import 'modaal';
declare global {
    interface JQuery {
        modaal(arg: any): JQuery;
    }
}

export class YouTubePlaylistService implements IYouTubePlaylistService {
    private readonly Configuration: IYouTubePlaylistConfig;

    constructor(config: IYouTubePlaylistConfig) {
        this.Configuration = config;
    }

    GeneratePlaylist(container: HTMLElement, model: PlaylistResponse, index: string, player: Array<Object>,
        init: boolean, layout: string, isModal: boolean) {
        const videoId: string = model.items[0].snippet.resourceId.videoId;
        const isSlider: boolean = layout.toLowerCase() === 'slider';
        const showPagination: boolean = !isSlider;
        const hideVideo: string = isModal ? 'style="display: none"' : '';

        let videoContainer: string = '';
        let listItems: string = '<div class="yt-playlist-items">';

        // Render video data.
        model.items.forEach((item, j) => {
            const publishedDate: string = this.RenderDate(item.snippet.publishedAt);
            const descriptionLength: number = this.Configuration.DescCharLength;
            let descriptionShort: string = item.snippet.description;
            if (item.snippet.description.length > descriptionLength) {
                descriptionShort = descriptionShort.substring(0, descriptionLength).trim();
                descriptionShort = `${descriptionShort}...`;
            }

            if (j === 0) {
                videoContainer =
                    `<div id="yt-video-player-container-${index}" class="yt-video-player-container" ${hideVideo}>
                        <div id="yt-video-player-wrapper-${index}" class="yt-video-player-wrapper">
                            <div id="yt-video-player-${index}" class="yt-video-player"></div>
                        </div>
                        <div class="yt-video-player-date">${publishedDate}</span></div>
                        <h2 class="yt-video-player-title">${item.snippet.title}</h2>
                        <div class="yt-video-player-description">${item.snippet.description}</div>
                    </div>`;
            }

            // Set first video as active and current.
            const active: string = j === 0 && !isModal ? 'yt-active' : 'yt-inactive';
            const current: string = j === 0 && !isModal ? 'true' : 'false';

            listItems +=
                `<div class="yt-video-item ${active}"
                    data-video-id="${item.snippet.resourceId.videoId}"
                    data-position="${item.snippet.position}"
                    data-description="${item.snippet.description}"
                    data-item-index="${j}"
                    tabindex="0"
                    aria-current="${current}"
                >
                    <div class="yt-image-container">
                    
                    <img src="${item.snippet.thumbnails[this.Configuration.ImageRes].url}"
                            class="yt-video-img" alt="${item.snippet.title}"
                        >
                    </div>
                    <div class="yt-text-container">
                        <div class="yt-published-date">${publishedDate}</div>
                        <div class="yt-video-title"><h3>${item.snippet.title}</h3></div>
                        <div class="yt-video-description">${descriptionShort}</div>
                    </div>`;
            listItems += '</div>';

        });

        listItems += '</div>';


        if ($('#yt-playlist-0').length > 0) {

            setTimeout(() => {
                $('.yt-playlist-items').each(function (i, item) {
                    $(this).on('click', function () {
                        window.location.href = "#PageContent";
                    });
                });
            }, 500);

        }

        let pagination: string = '';
        if (showPagination) {


            pagination =
                `<div id="yt-pagination-container-${index}" class="yt-pagination-container">
                    <button data-token="" data-id="" class="yt-pagination yt-pagination-prev"
                        style="display: none"
                        type="button"
                    >${this.Configuration.ButtonPrevText}</button>
                    <button data-token="" data-id="" class="yt-pagination yt-pagination-next"
                        style="display: none"
                        type="button"
                    >${this.Configuration.ButtonNextText}</button>
                </div>`;
        }

        let playlist: string;
        if (init) {
            playlist = `${videoContainer}${listItems}${pagination}`;
            container.setAttribute('data-player-index', String(index));
            container.insertAdjacentHTML('beforeend', playlist);
        } else {
            // Update Video Player details and list items.
            container.querySelectorAll('.yt-video-player-date')[0].textContent =
                this.RenderDate(model.items[0].snippet.publishedAt);
            container.querySelectorAll('.yt-video-player-title')[0].textContent =
                model.items[0].snippet.title;
            container.querySelectorAll('.yt-video-player-description')[0].textContent =
                model.items[0].snippet.description;
            container.querySelectorAll('.yt-playlist-items')[0].remove();
            container.querySelectorAll('.yt-video-player-container')[0]
                .insertAdjacentHTML('afterend', listItems);
        }

        // Render Pagination.
        if (showPagination) {
            let next: string;
            let prev: string;
            const paginationNext = document.getElementById(`yt-pagination-container-${index}`)
                .querySelectorAll('.yt-pagination-next')[0] as HTMLElement;
            const paginationPrev = document.getElementById(`yt-pagination-container-${index}`)
                .querySelectorAll('.yt-pagination-prev')[0] as HTMLElement;

            if (model.nextPageToken) {
                next = model.nextPageToken;
                paginationNext.setAttribute('data-token', next);
                paginationNext.setAttribute('data-id', String(index));
                paginationNext.style.display = 'block';
            } else {
                paginationNext.style.display = 'none';
            }

            if (model.prevPageToken) {
                prev = model.prevPageToken;
                paginationPrev.setAttribute('data-token', prev);
                paginationPrev.setAttribute('data-id', String(index));
                paginationPrev.style.display = 'block';
            } else {
                paginationPrev.style.display = 'none';
            }
        }

        // Initialize slider.
        if (isSlider) {
            (<any>$(`#yt-playlist-${index} .yt-playlist-items`)).slick(this.Configuration.SliderConfig);
        }

        // Initialize modals.
        if (isModal) {
            $('.yt-video-item').each((i, item) => {
                $(item).modaal({
                    content_source: `#yt-video-player-container-${index}`,
                    before_open: () => {
                        const title: string = item.querySelector('h3').textContent;
                        const videoId: string = item.getAttribute('data-video-id');
                        const description: string = item.getAttribute('data-description');
                        const published: string = item.querySelectorAll('.yt-published-date')[0].textContent;
                        const modal: HTMLElement = document.getElementById(`yt-video-player-container-${index}`);
                        modal.querySelectorAll('.yt-video-player-title')[0].textContent = title;
                        modal.querySelectorAll('.yt-video-player-description')[0].textContent = description;
                        modal.querySelectorAll('.yt-video-player-date')[0].textContent = published;
                        setTimeout(() => {
                            this.CueVideoById(player, index, videoId);
                        }, 1000);
                    }
                });
            });
        }

        // Initialize YouTube Player(s).
        const prefix: string = 'yt-video-player';
        this.InitializeYouTubePlayer(player, prefix, index);

        // Cue initial video.
        this.CueVideoById(player, index, videoId);
    }

    RenderDate(date) {
        const published: Date = new Date(date);
        const month: string = published.toLocaleString('default', { month: 'long' });
        const dayNum: number = published.getDate();
        const year: number = published.getFullYear();
        return `${month} ${dayNum}, ${year}`;
    }

    InitializeYouTubePlayer(player: Array<Object>, prefix: string, index: string) {
        // Set domain.
        const domain: string = (window as any).location.host;
        player.push(YouTubePlayer(`${prefix}-${index}`, {
            'host': 'https://www.youtube-nocookie.com',
            'playerVars': {
                'autoplay': this.Configuration.Autoplay,
                'cc_lang_pref': this.Configuration.CCLangPref,
                'cc_load_policy': this.Configuration.CCLoadPolicy,
                'color': this.Configuration.Color,
                'controls': this.Configuration.Controls,
                'disablekb': this.Configuration.DisableKB,
                'enablejsapi': this.Configuration.EnableJSAPI,
                'fs': this.Configuration.FS,
                'hl': this.Configuration.HL,
                'iv_load_policy': this.Configuration.IVLoadPolicy,
                'loop': this.Configuration.Loop,
                'modestbranding': this.Configuration.ModestBranding,
                'origin': domain,
                'playsinline': this.Configuration.PlaysInline,
                'rel': this.Configuration.Rel,
                'widget_referrer': domain
            }
        }));
    }

    CueVideoById(player: Array<Object>, index: string, id: string) {
        player[index].cueVideoById(id);
    }
}
