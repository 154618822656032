import { IYouTubePlaylistConfig } from '../../Interfaces/Configuration/IYouTubePlaylistConfig';

export class Configuration implements IYouTubePlaylistConfig {
    ApiKey: string = '';
    Autoplay: number = 1;
    ButtonNextText: string = 'Next';
    ButtonPrevText: string = 'Prev';
    CCLangPref: string = 'en';
    CCLoadPolicy: number = 0;
    Color: string = 'red';
    Controls: number = 1;
    DescCharLength: number = 100;
    DisableKB: number = 0;
    EnableJSAPI: number = 0;
    FS: number = 1;
    GoogleApiPath: string = 'https://www.googleapis.com/youtube/v3/playlistItems';
    HL: string = 'en';
    ImageRes: string = 'medium';
    IVLoadPolicy: number = 1;
    Layout: string = ''; // slider / grid / list.
    Loop: number = 0;
    MaxResults: number = 9; // Maximum result is 50.
    Modal: boolean = false;
    ModestBranding: number = 0;
    Origin: string = '';
    Part: string = 'snippet';
    PlaylistId: string = '';
    PlaysInline: number = 0;
    Rel: number = 1;
    SliderConfig: object = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1
    }
    TargetId: string = 'yt-playlist';
    WidgetReferrer: string = '';
}
