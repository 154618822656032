import { IYouTubePlaylistConfig } from "../Interfaces/Configuration/IYouTubePlaylistConfig";
import { PlaylistResponse } from "../Models/PlaylistResponse";

export class YouTubePlaylistDataService {
    private readonly Configuration: IYouTubePlaylistConfig;

    constructor(config: IYouTubePlaylistConfig) {
        this.Configuration = config;
    }

    async CallYouTubeAPI(url: string): Promise<PlaylistResponse> {
        return new Promise((resolve, reject) => {
            fetch(url).then((response) => response.json()).then((data) => {
                resolve(data);

            }).catch((error) => {
                console.log(error);
            });
        });
    }
}
