// Do imports.
import { Configuration } from "./Models/Configuration/Configuration";
import { IYouTubePlaylistDataService } from "./Interfaces/IYouTubePlaylistDataService";
import { YouTubePlaylistDataService } from "./Services/YouTubePlaylistDataService";
import { IYouTubePlaylistService } from "./Interfaces/IYouTubePlaylistService";
import { YouTubePlaylistService } from "./Services/YouTubePlaylistService";
import { GetSiblings } from "./Helpers/helpers";
import * as $ from 'jquery'; // required for Modaal and Slick Slider.
import 'modaal';

declare global {
    interface JQuery {
        modaal(arg: any): JQuery;
    }
}

export class Application {
    private readonly Configuration: Configuration;
    private YouTubePlaylistDataService: IYouTubePlaylistDataService;
    private YouTubePlaylistService: IYouTubePlaylistService;

    constructor(config: Configuration) {
        this.Configuration = new Configuration();
        Object.assign(this.Configuration, config);
        this.YouTubePlaylistDataService = new YouTubePlaylistDataService(this.Configuration);
        this.YouTubePlaylistService = new YouTubePlaylistService(this.Configuration);
    }

    public async RenderYouTubePlaylist() {
        // Get URL parts.
        const host: string = this.Configuration.GoogleApiPath;
        const part: string = `?part=${this.Configuration.Part}`;
        const apiKey: string = `&key=${this.Configuration.ApiKey}`;
        const maxResults: string = `&maxResults=${this.Configuration.MaxResults}`;
        let url: string = `${host}${part}${apiKey}${maxResults}`;

        // Set player and playlistIds array.
        let player: Array<object> = [];
        let playlistIds: Array<string> = [];
        this.Configuration.PlaylistId ? playlistIds.push(this.Configuration.PlaylistId) : '';

        // Set layout.
        let layout: string = this.Configuration.Layout;
        const isModal: boolean = this.Configuration.Modal;
        let init: boolean = true;

        const targetId: string = this.Configuration.TargetId;
        const targets = Array.from(document.querySelectorAll(`[id=${targetId}]`) as NodeListOf<HTMLElement>);

        // If no playlistId get ids from data attributes.
        if (playlistIds.length === 0) {
            targets.forEach((target, index) => {
                const dataId: string = target.dataset.playlistId.trim();
                playlistIds.push(dataId);
                const id: string = `${targetId}-${index}`;
                target.setAttribute('id', id);
            });
        } else {
            const id: string = `${targetId}-0`;
            targets[0].setAttribute('id', id);
        }

        for (const id of playlistIds) {
            await this.YouTubePlaylistDataService.CallYouTubeAPI(`${url}&playlistId=${id}`).then((data) => {
                const index: string = String(playlistIds.indexOf(id));
                const container: HTMLElement = document.getElementById(`yt-playlist-${index}`);
                if (!this.Configuration.Layout) {
                    layout = container.dataset.layout.toLowerCase();
                }
                container.classList.add('yt-playlist', `yt-${layout}`);
                this.YouTubePlaylistService.GeneratePlaylist(container, data, index, player, init, layout, isModal);
            });
        }

        const playlists: NodeListOf<HTMLElement> = document.querySelectorAll('.yt-playlist');
        playlists.forEach(playlist => {
            playlist.addEventListener('click', event => {
                Application.HandleVideoSelect(player, event, layout, isModal);
            });
            playlist.addEventListener('keypress', event => {
                if (event.key === 'Enter') {
                    isModal ? $(event.target).modaal('open') : Application.HandleVideoSelect(player, event, layout, isModal);
                }
            });
        });

        // Handle pagination.
        const paginations: NodeListOf<HTMLElement> = document.querySelectorAll('.yt-pagination');
        paginations.forEach(pagination => {
            pagination.addEventListener('click', event => {
                event.preventDefault();

                const target = event.target as HTMLElement;
                const token: string = `&pageToken=${target.dataset.token}`;
                const id: string = target.dataset.id;
                const playlistId: string = this.Configuration.PlaylistId ? this.Configuration.PlaylistId
                    : target.closest('.yt-playlist').getAttribute('data-playlist-id');
                const playlistParam: string = `&playlistId=${playlistId}`;
                url = `${url}${playlistParam}${token}`;


                this.YouTubePlaylistDataService.CallYouTubeAPI(url).then((data) => {
                    const container: HTMLElement = document.getElementById(`yt-playlist-${id}`);
                    init = false;
                    this.YouTubePlaylistService.GeneratePlaylist(container, data, id, player, init, layout, isModal);
                });




            });
        });
    }

    private static HandleVideoSelect(player, event, layout, isModal) {
        const parent: string = '.yt-video-item';
        if (event.target.closest(parent)) {
            const item = event.target.closest(parent);
            const videoId: string = item.dataset.videoId;
            const playlistId: string = item.closest('.yt-playlist').id;
            const title: string = item.querySelector('h3').textContent;
            const description: string = item.dataset.description;
            const published: string = item.querySelector('.yt-published-date').textContent;
            const index: number = parseInt(item.closest('.yt-playlist').dataset.playerIndex);

            // Set active / inactive classes.
            item.classList.remove('yt-inactive');
            item.classList.add('yt-active');
            item.setAttribute('aria-current', 'true');

            const isSlider: boolean = layout === 'slider';

            if (isSlider) {
                const slide: HTMLElement = event.target.closest('.slick-slide');
                const siblings: NodeListOf<HTMLElement> = GetSiblings(slide);
                siblings.forEach(sibling => {
                    sibling.querySelectorAll(parent)[0].classList.remove('yt-active');
                    sibling.querySelectorAll(parent)[0].classList.add('yt-inactive');
                    sibling.querySelectorAll(parent)[0].setAttribute('aria-current', 'false');
                });
            } else {
                const siblings: NodeListOf<HTMLElement> = GetSiblings(item);
                siblings.forEach(sibling => {
                    sibling.classList.remove('yt-active');
                    sibling.classList.add('yt-inactive');
                    sibling.setAttribute('aria-current', 'false');
                });
            }
            Application.CueNewVideo(player, index, videoId, title, description, published, playlistId, isModal);
        }
    }

    public static CueNewVideo(player, index, videoId, title, description, published, playlistId, isModal) {
        player[index].cueVideoById(videoId);
        if (!isModal) {
            const playlist: HTMLElement = document.getElementById(`${playlistId}`);
            playlist.querySelectorAll('.yt-video-player-title')[0].textContent = title;
            playlist.querySelectorAll('.yt-video-player-description')[0].textContent = description;
            playlist.querySelectorAll('.yt-video-player-date')[0].textContent = published;
        }
    }
}
